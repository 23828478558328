import React, { useCallback, useState } from 'react'
import {
  View,
  Image,
  Text,
  FlatList,
  StyleSheet,
  Dimensions,
  Platform,
  TouchableOpacity,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { navigate } from '@/Navigators/utils'
import { MainPagesEnum } from '@/Navigators/Main'
import { PagesEnum } from '@/Navigators/Application'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'

import ExploreCard, { ExploreCardProps } from './components/exploreCard'
import useOpenReadMoreDialog from './hook/readMoreDialog'
import TextInputComponent, {
  TextInputComponentType,
} from './components/textInputComponent'

const mockExploreData: ExploreCardProps[] = [
  { title: '風味', description: '請推薦水果風味的烈酒，偏好酸一點' },
  {
    title: '酒感',
    description: '新手入門，想找酒感不要這麼重的單一純麥威士忌',
  },
  { title: '預算', description: '生日送禮用，不超過1500元的琴酒' },
  { title: '餐飲搭配', description: '牛排適合搭配什麼酒？' },
]

const AIChatContainer = ({ navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const openReadMoreDialog = useOpenReadMoreDialog()
  const styles = getStyle()

  const [inputFocus, setInputFocus] = useState(false)

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Home,
    })
  }, [navigation])

  const handleClickExploreCard = useCallback((keyword: string) => {
    navigate(AuthPagesEnum.AIChatRoomContainer, {
      keyword,
    })
  }, [])

  const handleSubmit = useCallback((keyword: string) => {
    navigate(AuthPagesEnum.AIChatRoomContainer, {
      keyword,
    })
  }, [])

  const renderExploreCard = useCallback(
    ({ item }: { item: ExploreCardProps }) => (
      <ExploreCard
        title={item.title}
        description={item.description}
        onPress={() => handleClickExploreCard(item.description)}
      />
    ),
    [handleClickExploreCard],
  )

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'烈酒答人'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[styles.container]}>
          <View style={[styles.descriptionContainer]}>
            <View style={[Gutters.tinyBMargin]}>
              <Image
                style={[styles.aiChatIcon]}
                source={Images.ai_chat_icon}
                resizeMode="contain"
              />
              <Image
                style={[styles.betaIcon]}
                source={Images.beta_icon}
                resizeMode="contain"
              />
            </View>
            <View style={[styles.descriptionTextContainer]}>
              <Text
                style={[
                  Fonts.size20,
                  Fonts.weight500,
                  { color: Colors.primary },
                ]}
              >
                您的私人酒侍
              </Text>
              <Text
                style={[
                  Fonts.size20,
                  Fonts.weight500,
                  { color: Colors.primary },
                ]}
              >
                問一次，選對烈酒
              </Text>
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                歡迎提問對烈酒感興趣的問題
              </Text>
            </View>
          </View>
          <View style={[styles.exploreContainer]}>
            <View
              style={[
                Layout.row,
                Layout.alignItemsCenter,
                Gutters.tinyBMargin,
                { gap: 8 },
              ]}
            >
              <Image
                style={[styles.hintIcon]}
                source={Images.hint_icon}
                resizeMode="contain"
              />
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                你可以探索以下類別，點擊卡片嘗試
              </Text>
            </View>
            <View style={styles.exploreCardContainer}>
              <FlatList
                data={mockExploreData}
                renderItem={renderExploreCard}
                keyExtractor={(_, index) => index.toString()}
                numColumns={2}
                columnWrapperStyle={styles.row}
                contentContainerStyle={styles.exploreCardContainer}
              />
            </View>
            {!inputFocus && (
              <View style={styles.statementContainer}>
                <Text
                  style={[
                    Fonts.size12,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >
                  特別聲明：本 Chatbot 使用 AI 技術生成
                </Text>
                <TouchableOpacity onPress={openReadMoreDialog}>
                  <Text
                    style={[
                      Fonts.size12,
                      Fonts.weight400,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    了解更多
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
          <TextInputComponent
            type={TextInputComponentType.DEFAULT}
            placeholder={'想找什麼風味的酒'}
            onSubmit={handleSubmit}
            setInputFocus={setInputFocus}
          />
        </View>
      </FadeInView>
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: Platform.OS === 'web' ? 50 : 0,
    },
    container: {
      flex: 1,
      padding: 16,
    },
    descriptionContainer: {
      alignItems: 'center',
      marginBottom: 24,
    },
    descriptionTextContainer: {
      alignItems: 'center',
      gap: 8,
    },
    aiChatIcon: {
      width: 74,
      height: 74,
      marginBottom: 8,
    },
    betaIcon: {
      width: 32,
      height: 15,
      position: 'absolute',
      left: 20,
      bottom: 0,
    },
    exploreContainer: {
      flex: 1,
    },
    hintIcon: {
      width: 24,
      height: 24,
    },
    exploreCardContainer: {
      flexGrow: 1,
    },
    row: {
      flex: 1,
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    statementContainer: {
      flexDirection: 'row',
      padding: 16,
      ...Platform.select({
        web: {
          justifyContent: 'space-between',
        },
        ios: {
          justifyContent: 'space-between',
        },
        android: {
          justifyContent: 'space-around',
          paddingHorizontal: 4,
        },
      }),
      marginTop: 'auto',
      backgroundColor: Colors.background.surface,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
  })

export default AIChatContainer
